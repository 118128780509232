import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Route, Router, CanLoad, CanActivateChild} from '@angular/router';
import {Observable, of} from 'rxjs';
import {catchError, mapTo, switchMap} from 'rxjs/operators';
import {AuthService} from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad, CanActivateChild {
  constructor(
    private authService: AuthService,
    private router: Router) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const url: string = state.url;

    return this.checkLogin(url);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(route, state);
  }

  canLoad(route: Route): Observable<boolean> {
    return this.checkLogin();
  }

  checkLogin(url?: string) {
    return of({}).pipe(
      switchMap(() => {
        if (this.authService.authData) {
          if (!this.authService.isAuth) {
            return this.authService.refreshToken().pipe(
              mapTo(true)
            );
          } else {
            return of(true);
          }
        }
        const redirectUrl: string = url ? url : window.location.pathname;
        this.router.navigate(['/login'], { queryParams: { returnUrl: redirectUrl } });

        return of(false);
      }),
      catchError(() => {
        if (!this.authService.authData) {
          const redirectUrl: string = url ? url : window.location.pathname;
          this.router.navigate(['/login'], { queryParams: { returnUrl: redirectUrl } });

          return of(false);
        }

        return of(false);
      })
    );
  }
}
