import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConfig} from '../app.config';
import {Observable} from 'rxjs';
import {UserModel} from '../features/user-management/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserManagementApiService {

  private readonly baseUrl;

  constructor(private http: HttpClient, private appConfig: AppConfig) {
    this.baseUrl = `${appConfig.apiUrl}/users`;
  }

  getUsers(): Observable<{ data: UserModel[] }> {
    return this.http.get<{ data: UserModel[] }>(this.baseUrl);
  }

  getUserById(id): Observable<{ data: UserModel }> {
    return this.http.get<{ data: UserModel }>(`${this.baseUrl}/${id}`);
  }

  createUser(body): Observable<any> {
    return this.http.post(this.baseUrl, body);
  }

  updateUser(userId, body) {
    return this.http.patch(`${this.baseUrl}/${userId}`, body);
  }

  removeUser(userId) {
    return this.http.delete(`${this.baseUrl}/${userId}`);
  }

  getUsersCount(): Observable<any> {
    return this.http.get(`${this.baseUrl}/count`);
  }
}
