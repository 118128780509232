<div class="bottom-image"></div>
<nz-layout class="app-layout">
  <nz-sider class="menu-sidebar"
            nzCollapsible
            nzWidth="256px"
            nzBreakpoint="md"
            nzTheme="light"
            [(nzCollapsed)]="isCollapsed"
            [nzTrigger]="null">
    <div class="sidebar-header">
      <ng-container *ngIf="!isCollapsed">
        <div class="top-image"></div>
        <a class="menu-logo" href="/">
          <span class="main-logo-text">HEUREKA</span>
          <span class="main-logo-description">BUSINESS SOLUTION</span>
        </a>
        <ng-container *ngIf="userId">
          <div class="user-container" *ngIf="user$ | async as user">
            <div class="user-logo">
              <app-image-preview
                *ngIf="user.avatarId; else avatarTemplate"
                [src]="user.avatarId"
                [size]="64"
              ></app-image-preview>
              <ng-template #avatarTemplate>
                <img src="assets/images/person.png" alt="">
              </ng-template>
            </div>
            <p>{{ user?.lastName || user.firstName ? user?.firstName + ' ' + user.lastName : user.username }}</p>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <ul nz-menu nzTheme="light" nzMode="inline" [nzInlineCollapsed]="isCollapsed">
      <li nz-menu-item nzSelected nzMatchRouter routerLink="/user-management">
        <a routerLink="/user-management" nz-icon nzType="user"></a>
        <span>User management</span>
      </li>
      <li nz-menu-item nzSelected nzMatchRouter routerLink="/project-management">
        <a routerLink="/project-management" nz-icon nzType="swap"></a>
        <span>Project management</span>
      </li>
<!--      <li nz-menu-item nzSelected nzMatchRouter routerLink="/smiley-management">-->
<!--        <a routerLink="/smiley-management" nz-icon nzType="smile"></a>-->
<!--        <span>Smiley management</span>-->
<!--      </li>-->
      <li nz-menu-item nzSelected nzMatchRouter routerLink="/settings">
        <a routerLink="/settings" nz-icon nzType="setting"></a>
        <span>Settings</span>
      </li>
    </ul>
    <span *ngIf="!isCollapsed" class="footer-text">©2020 Heureka</span>
  </nz-sider>
  <nz-layout>
    <nz-header>
      <div class="app-header">
        <button nz-button nzType="default" nzSize="large" class="header-trigger" (click)="isCollapsed = !isCollapsed">
            <i class="trigger"
               nz-icon
               [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
            ></i>
        </button>
        <span class="header-text">HEUREKA</span>
        <div class="side-buttons">
          <button nz-button nzType="default" nzSize="large" class="header-trigger" style="padding: 10px;"
                  nzPopoverTrigger="click" nz-popover [nzPopoverContent]="notifications" [nzPopoverPlacement]="'bottomRight'">
            <img width="20px" style="vertical-align: baseline;" src="assets/icons/bell.png" alt="">
          </button>
          <ng-template #notifications>
            <app-messages></app-messages>
          </ng-template>
          <button nz-button nzType="default" nzSize="large" class="header-trigger" nzPopoverTrigger="click"
                  nz-popover
                  [nzPopoverContent]="moreTemplate">
            <i class="trigger"
               nz-icon
               nzType="more"
            ></i>
            <ng-template #moreTemplate>
              <div>
                <button nz-button nzType="default" routerLink="/login">Log out</button>
              </div>
            </ng-template>
          </button>
        </div>
      </div>
    </nz-header>
    <nz-content>
      <div class="inner-content">
        <router-outlet></router-outlet>
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>
