import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import {FormBuilder, FormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { HomeComponent } from './public/home/home.component';
import { LeftMenuComponent } from './public/left-menu/left-menu.component';
import {RouterModule} from '@angular/router';
import {AppRoutingModule} from './app-routing.module';
import {
  NzButtonModule,
  NzIconModule,
  NzLayoutModule,
  NzMenuModule, NzModalService,
  NzNotificationService,
  NzPopoverModule, NzTabsModule,
  NzWaveModule
} from 'ng-zorro-antd';
import {AppConfig} from './app.config';
import {HttpRequestInterceptor} from './services/http-request.interceptor';
import {HttpErrorInterceptor} from './services/http-error.interceptor';
import { MessagesComponent } from './public/messages/messages.component';
import {SharedModule} from './shared/shared.module';

registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LeftMenuComponent,
    MessagesComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    RouterModule,
    AppRoutingModule,
    NzLayoutModule,
    NzMenuModule,
    NzIconModule,
    NzWaveModule,
    NzButtonModule,
    NzPopoverModule,
    SharedModule,
    NzTabsModule
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    AppConfig,
    NzNotificationService,
    NzModalService,
    FormBuilder
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
