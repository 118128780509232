import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConfig} from '../app.config';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertApiService {

  private readonly baseUrl;

  constructor(private http: HttpClient, private appConfig: AppConfig) {
    this.baseUrl = `${appConfig.apiUrl}/alerts`;
  }

  createAlert(id, type, body) {
    return this.http.post(`${this.appConfig.apiUrl}/entities/${id}/type/${type}/alerts`, body);
  }

  updateAlerts(id, body) {
    return this.http.put(`${this.baseUrl}/${id}`, body);
  }

  getAlertById(alertId): Observable<{ data: any }> {
    return this.http.get<{ data: any }>(`${this.baseUrl}/${alertId}`);
  }

  getAllAlerts(): Observable<{ data: any }> {
    return this.http.get<{ data: any }>(`${this.baseUrl}`);
  }

  removeAlert(alertId) {
    return this.http.delete(`${this.baseUrl}/${alertId}`);
  }
}
