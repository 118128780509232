import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from '../auth/services/auth.service';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {


  constructor(private authService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!request.url.includes('login')) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.authService.authData.accessToken}`,
        }
      });
    }

    return next.handle(request);
  }
}
