import {Component, Input, OnInit} from '@angular/core';
import {NzModalRef, NzNotificationService} from 'ng-zorro-antd';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AlertApiService} from '../../../../services/alert-api.service';
import {UserModel} from '../../../user-management/models/user.model';
import {catchError, filter, switchMap, takeUntil, tap} from 'rxjs/operators';
import {ErrorService} from '../../../../services/error.service';
import {EMPTY, of, Subject} from 'rxjs';
import {ProjectsApiService} from '../../../../services/projects-api.service';
import {TaskApiService} from '../../../../services/task-api.service';
import {getAlertDays} from '../../utils/days.util';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss']
})
export class AlertModalComponent implements OnInit {

  @Input() object: any;
  @Input() type: any;
  @Input() parentId: any;
  @Input() confirmFn;


  submitForm$ = new Subject();
  getAccessUsers$ = new Subject();

  alertForm$: FormGroup;
  loading = false;
  reducedUsers: { [key: string]: UserModel };
  options = {
    usersIds: [],
    reminderDays: getAlertDays(14)
  };
  active = true;

  private destroy$ = new Subject();

  constructor(
    private notification: NzNotificationService,
    private projectsApiService: ProjectsApiService,
    private taskApiService: TaskApiService,
    private alertApiService: AlertApiService,
    private modal: NzModalRef,
    private fb: FormBuilder
  ) {
    this.getAccessUsers$.pipe(
      switchMap(() => this.type === 'project'
        ? this.projectsApiService.getProjectUsers(this.parentId) : this.taskApiService.getAccessUsers(this.parentId)),
      catchError(error => ErrorService.errorHandler(error, this.notification, 'Load users error')),
      takeUntil(this.destroy$)
    ).subscribe(({ data }) => {
      this.reducedUsers = data.reduce((acc, user) => ({ ...acc, [user.id]: user}), {});
      this.options.usersIds = data.map(user =>
        ({ label: (user.firstName || user.lastName) ? user.firstName + ' ' + user.lastName : user.username, value: user.id }));
    });

    this.submitForm$.pipe(
      filter(() => {
        // tslint:disable-next-line:forin
        for (const i in this.alertForm$.controls) {
          this.alertForm$.controls[i].markAsDirty();
          this.alertForm$.controls[i].updateValueAndValidity();
        }

        return this.alertForm$.valid;
      }),
      tap(() => {
        this.loading = true;
      }),
      switchMap(() => {
        const values = { ...this.alertForm$.value, alertTime: this.convertTimeToSeconds(this.alertForm$.value.alertTime)};
        const request = this.object
          ? this.alertApiService.updateAlerts(this.object.id, values) : this.alertApiService.createAlert(this.parentId, this.type, values);

        return request.pipe(
          switchMap(() => this.object ? this.alertApiService.getAlertById(this.object.id) : of(null)),
          catchError((error => {
            this.loading = false;

            return ErrorService.errorHandler(error, this.notification, `Alert error`);
          }))
        );
      })
    ).subscribe(item => {
      this.loading = false;
      this.notification.success(`Successfully ${this.object ? 'updated' : 'created'}`, '');
      this.confirmFn(item.data);
      this.closeModal();
    });
  }

  ngOnInit(): void {
    this.alertForm$ = this.fb.group({
      alertTime: [this.object ? this.convertSecondsToTime(this.object.alertTime) : null, [Validators.required]],
      reminderDays: [this.object ? this.object.reminderDays : null, [Validators.required]],
      usersIds: [this.object ? this.object.usersIds : null, [Validators.required]],
    });

    this.getAccessUsers$.next();
  }

  convertSecondsToTime(seconds) {
    const date = new Date().setHours(0, 0, 0, 0);

    return new Date(date).setSeconds(seconds);
  }

  convertTimeToSeconds = (date: Date) => {
    const minutes = new Date(date).getMinutes();
    const hours = new Date(date).getHours();

    return hours * 3600 + minutes * 60;
  }

  closeModal = () => this.modal.close();
}
