import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConfig} from '../app.config';
import {Observable} from 'rxjs';

export interface MessageModel {
  id: number;
  content: { de: string, en: string };
  createTimestamp: string;
  isNew: boolean;
}

@Injectable({
  providedIn: 'root'
})

export class MessagesApiService {

  private readonly baseUrl;

  constructor(private http: HttpClient, private appConfig: AppConfig) {
    this.baseUrl = `${appConfig.apiUrl}/messages`;
  }

  getMessages(): Observable<{ data: MessageModel[] }> {
    return this.http.get<{ data: MessageModel[] }>(this.baseUrl);
  }
}
