import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConfig} from '../app.config';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TaskApiService {

  private readonly baseUrl;
  private readonly baseAdminUrl;

  constructor(private http: HttpClient, private appConfig: AppConfig) {
    this.baseUrl = `${appConfig.apiUrl}/tasks`;
    this.baseAdminUrl = `${appConfig.apiUrl}/admin/tasks`;
  }

  createSubTask(taskId, body): Observable<any> {
    return this.http.post(`${this.baseAdminUrl}/${taskId}/sub-tasks`, body);
  }

  updateTask(id, body): Observable<any> {
    return this.http.patch(`${this.baseAdminUrl}/${id}`, body);
  }

  getTaskById(id): Observable<{ data: any }> {
    return this.http.get<{ data: any }>(`${this.baseAdminUrl}/${id}`);
  }

  removeTask(id) {
    return this.http.delete<{ data: any }>(`${this.baseAdminUrl}/${id}`);
  }

  getAccessUsers(taskId): Observable<{ data: any }> {
    return this.http.get<{ data: any }>(`${this.baseAdminUrl}/${taskId}/users/access`);
  }

  getSubTasks(taskId): Observable<{ data: any }> {
    return this.http.get<{ data: any }>(`${this.baseAdminUrl}/${taskId}/sub-tasks/extended`);
  }

  addFilesToTask(taskId, files) {
    return this.http.post(`${this.baseAdminUrl}/${taskId}/files`, files);
  }
}
