import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConfig} from '../app.config';
import {Observable} from 'rxjs';
import {ProjectModel} from '../features/project-management/models/project.model';
import {UserModel} from '../features/user-management/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class ProjectsApiService {

  private readonly baseUrl;
  private readonly baseAdminUrl;

  constructor(private http: HttpClient, private appConfig: AppConfig) {
    this.baseUrl = `${appConfig.apiUrl}/projects`;
    this.baseAdminUrl = `${appConfig.apiUrl}/admin/projects`;
  }

  // getProjects(): Observable<{ data: { projects: ProjectModel[], users: UserModel[] } }> {
  //   return this.http.get<{ data: { projects: ProjectModel[], users: UserModel[] } }>
  //   (`${this.baseAdminUrl}/extended?includeChildrenCount=true`);
  // }
  getProjects(body): Observable<{ data: { projects: ProjectModel[], users: UserModel[] } }> {
    body = { ...body, includeChildrenCount: true };

    return this.http.post<{ data: { projects: ProjectModel[], users: UserModel[] } }>(`${this.baseAdminUrl}/extended`, body);
  }

  getProjectById(id): Observable<{ data: ProjectModel }> {
    return this.http.get<{ data: ProjectModel }>(`${this.baseAdminUrl}/${id}`);
  }

  getProjectsCount(): Observable<{ data: any }> {
    return this.http.get<{ data: any }>(`${this.baseAdminUrl}/count`);
  }

  getRootProjects(): Observable<{ data: ProjectModel[] }> {
    return this.http.get<{ data: ProjectModel[] }>(`${this.baseUrl}/root`);
  }

  getChildrenProjects(): Observable<{ data: ProjectModel[] }> {
    return this.http.get<{ data: ProjectModel[] }>(`${this.baseUrl}/sub-projects`);
  }

  getAllObjects(): Observable<{ data: { projects: [], tasks: [], milestones: [], count: any } }> {
    return this.http.get<{ data: { projects: [], tasks: [], milestones: [], count: any } }>(`${this.baseUrl}/extended-info`);
  }

  createProject(body): Observable<any> {
    return this.http.post(this.baseAdminUrl, body);
  }

  removeProject(id) {
    return this.http.delete(`${this.baseAdminUrl}/${id}`);
  }

  updateProject(id, body) {
    return this.http.patch(`${this.baseAdminUrl}/${id}`, body);
  }

  getProjectUsers(id): Observable<{ data: any }> {
    return this.http.get<{ data: any }>(`${this.baseAdminUrl}/${id}/users/assignee`);
  }

  assignUsersToProject(id, body) {
    return this.http.put(`${this.baseAdminUrl}/${id}/users/assignee`, body);
  }

  getProjectTasks(projectId: number): Observable<{ data: any }> {
    return this.http.get<{ data: any }>(`${this.baseAdminUrl}/${projectId}/tasks/extended?includeSubTasksCount=true`);
  }

  createTaskForProject(projectId, body) {
    return this.http.post(`${this.baseAdminUrl}/${projectId}/tasks`, body);
  }

  getProjectSubTasks(projectId: number): Observable<{ data: any }> {
    return this.http.get<{ data: any }>(`${this.baseAdminUrl}/${projectId}/sub-tasks/extended`);
  }

  addFilesToProject(projectId, files) {
    return this.http.post(`${this.baseAdminUrl}/${projectId}/files`, files);
  }
}
