import { Injectable } from '@angular/core';
import {NzNotificationService} from 'ng-zorro-antd';
import {EMPTY} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor() { }

  public static errorHandler({ error } , notification: NzNotificationService, topic?: string) {
    notification.error(topic || '', error && error.error ? error.error.message : '');

    return EMPTY;
  }
}
