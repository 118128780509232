import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {UserModel} from '../../features/user-management/models/user.model';
import {UserManagementApiService} from '../../services/user-management-api.service';
import {UsersService} from '../../services/users.service';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  userId = this.parseJwt(JSON.parse(localStorage.getItem('currentUser')).accessToken).sub;
  user$: Observable<UserModel> = this.userManagementApiService.getUserById(this.userId).pipe(
    map(({data}) => data)
  );
  isCollapsed = false;

  constructor(
    private userManagementApiService: UserManagementApiService,
    private userService: UsersService
  ) {
    this.userService.userId = this.parseJwt(JSON.parse(localStorage.getItem('currentUser')).accessToken).sub;
  }

  ngOnInit(): void {
  }

  parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  };

}
