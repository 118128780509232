<div class="messages-container">
  <nz-tabset>
    <nz-tab *ngFor="let tab of tabs" [nzTitle]="tab.name">
      <ng-container *ngIf="tab.name === 'Alerts'">
        <ng-container *ngTemplateOutlet="alertTemplate"></ng-container>
      </ng-container>
      <ng-container *ngIf="tab.name === 'Messages'">
        <ng-container *ngTemplateOutlet="messagesTemplate"></ng-container>
      </ng-container>
    </nz-tab>
  </nz-tabset>
</div>

<ng-template #messagesTemplate>
  <ng-container *ngIf="messages$ | async as messages">
    <ng-container *ngIf="messages?.length; else emptyList">
      <div class="message-list">
        <div *ngFor="let message of messages" class="message-item" >
          <span class="message-text">{{ message?.content?.en }}</span>
          <span class="message-date">{{ message?.createTimestamp | date: 'dd.MM.yyyy'}}</span>
        </div>
      </div>
    </ng-container>
    <ng-template #emptyList>
      <div style="text-align: center">No messages</div>
    </ng-template>
  </ng-container>
</ng-template>
<ng-template #alertTemplate>
  <div class="message-list">
    <ng-container *ngIf="alerts?.length; else emptyList">
      <div *ngFor="let alert of alerts" class="alert-item">
        <div class="alert-header">
          <i nz-icon nzType="edit" nzTheme="outline" (click)="showAlertModal(alert)"></i>
          <i nz-icon nzType="delete" nzTheme="outline"></i>
        </div>
        <div class="alert-type">Name: <span>{{ alert?.entityName }}</span></div>
        <div class="alert-type">Type: <span>{{ alert?.entityType }}</span></div>
        <div class="alert-date">
          <div class="remind-time">Remind in <span>{{ alert?.reminderDays }}</span> day(s), at <span>{{ alert.remindIn | date: 'HH:mm' }}</span> </div>
        </div>
        <div class="alert-date">
          Created:  {{ alert?.createTimestamp | date: 'dd.MM.yyyy HH:mm'}}
        </div>
      </div>
    </ng-container>
    <ng-template #emptyList>
      <div style="text-align: center">No alerts</div>
    </ng-template>
  </div>
</ng-template>

