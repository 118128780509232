import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-column-settings-popover',
  templateUrl: './column-settings-popover.component.html',
  styleUrls: ['./column-settings-popover.component.scss']
})
export class ColumnSettingsPopoverComponent implements OnInit {

  @Input() columnList;
  @Output() changeColumnList = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
