import {Component, OnDestroy, OnInit} from '@angular/core';
import {MessageModel, MessagesApiService} from '../../services/messages-api.service';
import {Observable, Subject} from 'rxjs';
import {catchError, map, switchMap, takeUntil, tap} from 'rxjs/operators';
import {AlertApiService} from '../../services/alert-api.service';
import {ErrorService} from '../../services/error.service';
import {NzModalService, NzNotificationService} from 'ng-zorro-antd';
import {AlertModalComponent} from '../../features/project-management/modals/alert-modal/alert-modal.component';
import {ProjectModel} from '../../features/project-management/models/project.model';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit, OnDestroy {

  alerts: any[];
  messages$: Observable<MessageModel[]>;
  removeAlert$ = new Subject();
  loadAlerts$ = new Subject();
  tabs = [
    { name: 'Alerts' },
    { name: 'Messages' }
  ];

  private destroy$ = new Subject();

  constructor(
    private alertsApiService: AlertApiService,
    private notification: NzNotificationService,
    private messagesApiService: MessagesApiService,
    private modalService: NzModalService
  ) {
    this.messages$ = this.messagesApiService.getMessages().pipe(
      map(({ data }) => data)
    );

    this.loadAlerts$.pipe(
      switchMap(() => this.alertsApiService.getAllAlerts().pipe(
        catchError(error => ErrorService.errorHandler(error, this.notification, 'Load projects error')),
      ))
    ).subscribe(({ data }) => this.alerts = data.map(item => ({ ...item, remindIn: this.convertSecondsToTime(item.alertTime) })));

    this.removeAlert$.pipe(
      switchMap(id => this.alertsApiService.removeAlert(id).pipe(
        tap(() => this.alerts = this.alerts.filter(item => item.id !== id))
      )),
      takeUntil(this.destroy$)
    ).subscribe();
  }

  ngOnInit(): void {
    this.loadAlerts$.next();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  showAlertModal(alert) {
    this.modalService.create({
      nzContent: AlertModalComponent,
      nzWidth: '400px',
      nzComponentParams: {
        object: alert,
        type: alert.entityType,
        parentId: alert.entityId,
        confirmFn: item => this.alerts = this.addObjectToList(item, this.alerts)
      },
      nzFooter: null
    });
  }

  convertSecondsToTime(seconds) {
    const date = new Date().setHours(0, 0, 0, 0);

    return new Date(date).setSeconds(seconds);
  }

  addObjectToList(alert, alerts): ProjectModel[] {
    const foundAlertIndex = alerts.findIndex(a => a.id === alert.id);
    if (foundAlertIndex !== -1) {
      return alerts.map(a => a.id !== alert.id ? a : { ...a, ...alert });
    } else {
      return [alert, ...alerts];
    }
  }

}
