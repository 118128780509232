import {Component, Input, OnInit} from '@angular/core';
import {FilesApiService} from '../../../services/files-api.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss']
})
export class ImagePreviewComponent implements OnInit {

  getUrl$: Observable<any>;
  url;

  @Input() src: string;
  @Input() size: string | number = 'default';

  constructor(
    private filesApiService: FilesApiService,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    this.getUrl$ = this.filesApiService.downloadFile(this.src).pipe(
      map(file => this.sanitize(window.URL.createObjectURL(file)))
    );
  }

  sanitize(url: string): any {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
}
