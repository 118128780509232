import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColumnSettingsPopoverComponent } from './components/column-settings-popover/column-settings-popover.component';
import {NzAvatarModule, NzCheckboxModule, NzGridModule, NzIconModule, NzPopoverModule} from 'ng-zorro-antd';
import {FormsModule} from '@angular/forms';
import { ImagePreviewComponent } from './components/image-preview/image-preview.component';



@NgModule({
  declarations: [ColumnSettingsPopoverComponent, ImagePreviewComponent],
    exports: [
        ColumnSettingsPopoverComponent,
        ImagePreviewComponent
    ],
  imports: [
    CommonModule,
    NzIconModule,
    NzPopoverModule,
    NzCheckboxModule,
    NzGridModule,
    FormsModule,
    NzAvatarModule
  ]
})
export class SharedModule { }
