<div class="modal-container">
  <div class="modal-header">
    <span style="font-size: 1.5em; font-weight: 500">Alert</span>
    <div class="active-toggle">
      <span>{{ active ? 'Active' : 'inactive' }}</span>
      <nz-switch [(ngModel)]="active"></nz-switch>
    </div>
  </div>
  <div class="modal-body">
    <form nz-form nzLayout="vertical" [formGroup]="alertForm$" (ngSubmit)="submitForm$.next()">
      <nz-form-item>
        <nz-form-control nzErrorTip="Select user!">
          <nz-form-label>Alert x days before due date</nz-form-label>
          <nz-select
            formControlName="reminderDays"
            nzPlaceHolder="Select days"
            nzShowSearch
            nzSize="large"
            [nzOptions]="options['reminderDays']"
          >
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <div class="date-container">
        <nz-form-item style="width: 100%;">
          <nz-form-control>
            <nz-form-label>Alert time</nz-form-label>
            <nz-time-picker formControlName="alertTime" nzFormat="HH:mm" nzSuffixIcon="history"></nz-time-picker>
          </nz-form-control>
        </nz-form-item>
      </div>
      <nz-form-item>
        <nz-form-control nzErrorTip="Select user!">
          <nz-form-label>Alert to</nz-form-label>
          <nz-select
            formControlName="usersIds"
            nzPlaceHolder="Select users"
            nzSize="large"
            nzMode="multiple"
            [nzCustomTemplate]="multipleTemplate"
            [nzOptions]="options['usersIds']"
          >
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <button class="submit-button" nz-button nzType="default" nzSize="large">
        {{ object ? 'Update' : 'Create' }}
      </button>
    </form>
  </div>
</div>
<ng-template #suffixIcon></ng-template>
<ng-template #multipleTemplate let-selected>
  <div style="display: flex; align-items: center">
    <app-image-preview
      *ngIf="reducedUsers[+selected.nzValue]?.avatarId; else avatarTemplate"
      [src]="reducedUsers[+selected.nzValue]?.avatarId"
    ></app-image-preview>
    <ng-template #avatarTemplate>
      <nz-avatar nzIcon="user" style="border-radius: 15%;"></nz-avatar>
    </ng-template>
    <span style="margin-left: 5px">{{ selected.nzLabel }}</span>
  </div>
</ng-template>
