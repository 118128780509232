<img
  class="settings-icon"
  src="assets/icons/settings.png"
  alt=""
  height="23px"
  nz-popover
  nzPopoverTrigger="click"
  [nzPopoverPlacement]="'bottomRight'"
  [nzPopoverContent]="contentTemplate"
>
<ng-template #contentTemplate>
  <div class="settings-container">
    <nz-checkbox-group [(ngModel)]="columnList" (ngModelChange)="changeColumnList.emit(columnList)"></nz-checkbox-group>
    <span class="bottom-button" (click)="changeColumnList.emit([])">SET DEFAULT</span>
  </div>
</ng-template>
