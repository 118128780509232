import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './public/home/home.component';
import {AuthGuard} from './auth/guards/auth.guard';

const routes: Routes = [
  { path: '',
    component: HomeComponent,
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
    children: [
      { path: '', redirectTo: 'user-management', pathMatch: 'full' },
      {
        path: 'user-management',
        loadChildren: () => import('./features/user-management/user-management.module').then(m => m.UserManagementModule)
      },
      {
        path: 'project-management',
        loadChildren: () => import('./features/project-management/project-management.module').then(m => m.ProjectManagementModule)
      },
      {
        path: 'smiley-management',
        loadChildren: () => import('./features/smiley-management/smiley-management.module').then(m => m.SmileyManagementModule)
      },
      {
        path: 'settings',
        loadChildren: () => import('./features/settings/settings.module').then(m => m.SettingsModule)
      }
    ]
  },
  { path: 'login', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
