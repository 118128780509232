import {HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {Injectable} from '@angular/core';
import {AuthService} from '../auth/services/auth.service';
import {catchError, filter, switchMap, take} from 'rxjs/operators';
import {Router} from '@angular/router';
import {NzNotificationService} from 'ng-zorro-antd';

@Injectable({providedIn: 'root'})
export class HttpErrorInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private authService: AuthService,
    private router: Router,
    private notification: NzNotificationService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // @ts-ignore
    return next.handle(request).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && request.url.includes('refresh')) {
          this.authService.setAuthData(null);
          localStorage.clear();
          this.router.navigate(['/login']);
        }
        if (error instanceof HttpErrorResponse && error.status === 401) {
          if (this.refreshTokenInProgress) {
            return this.refreshTokenSubject.pipe(
              filter(result => result !== null),
              take(1),
              switchMap(() => next.handle(this.addAuthenticationToken(request)))
            );
          } else {
            this.refreshTokenInProgress = true;

            this.refreshTokenSubject.next(null);

            return this.authService
              .refreshToken().pipe(
                switchMap((data) => {
                  this.refreshTokenInProgress = false;
                  this.refreshTokenSubject.next(data.refreshToken);

                  return next.handle(this.addAuthenticationToken(request));
                }), catchError((err: any) => {
                  this.refreshTokenInProgress = false;

                  this.router.navigate(['/login']);
                  return throwError(error);
                }));
          }
        } else {
          if (error instanceof HttpErrorResponse && error.status === 0) {
            this.notification.error('Server not responding', 'Please try again, or refresh the page.');
          }

          return throwError(error);
        }
      }));
  }

  addAuthenticationToken(request) {
    const accessToken = this.authService.authData.accessToken;

    if (!accessToken) {
      return request;
    }

    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`,
      }
    });
  }
}
