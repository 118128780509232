import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {AppConfig} from '../../app.config';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public currentUser: Observable<any> | null;
  public isAuth = false;
  public authData;

  private readonly baseUrl: string;

  constructor(
    private appConfig: AppConfig,
    private http: HttpClient,
  ) {
    this.baseUrl = `${this.appConfig.apiUrl}/auth`;
    const data = localStorage.getItem('currentUser');
    this.authData = data ? JSON.parse(data) : null;
  }

  setAuthData(data) {
    this.isAuth = !!data;
    this.authData = data;
    localStorage.setItem('currentUser', JSON.stringify(data));
  }

  login(body: { username: string, password: string }): Observable<any> {
    return this.http.post(`${this.baseUrl}/login`, body);
  }

  logout() {
    return this.http.delete(`${this.baseUrl}/token`, {
      headers:  new HttpHeaders({
        Authorization: `Bearer ${this.authData.accessToken}`,
      })
    });
  }

  refreshToken(): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/refresh?token=${this.authData.refreshToken}`, {}).pipe(
      tap(({ data }) => this.setAuthData(data))
    );
  }
}
