import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConfig} from '../app.config';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilesApiService {

  private readonly baseUrl;

  constructor(private http: HttpClient, private appConfig: AppConfig) {
    this.baseUrl = `${appConfig.apiUrl}/files`;
  }

  downloadFile(fileId) {
    return this.http.get(`${this.baseUrl}/${fileId}`, { responseType: 'blob'});
  }

  uploadFile(body): Observable<any> {
    return this.http.post(this.baseUrl, body);
  }

  uploadFiles(body): Observable<any> {
    return this.http.post(`${this.baseUrl}/upload`, body);
  }
}
